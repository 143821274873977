<template>
  <v-container grid-list-lg fluid>
    <v-card outlined>
      <v-toolbar dense flat color="grey lighten-3">
        <v-app-bar-nav-icon @click="$router.go(-1)">
          <v-icon>mdi-chevron-left</v-icon>
        </v-app-bar-nav-icon>

        <v-toolbar-title>Inventory Count</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn v-if="countStarted" text @click="restart()">Restart</v-btn>
      </v-toolbar>

      <v-card-title primary-title>
        Select Product You Want To Count
      </v-card-title>
      <v-card-text>
        <v-autocomplete
          v-model="selectedProduct"
          hide-no-data
          ref="productSearch"
          :disabled="countStarted"
          outlined
          autofocus
          :loading="productSearchLoading"
          prepend-inner-icon="search"
          rounded
          allow-overflow
          dense
          auto-select-first
          single-line
          clearable
          eager
          id="productSearch"
          :items="Products"
          item-text="name"
          return-object
          :label="$t('labels.searchProduct')"
        >
        </v-autocomplete>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <div>
          <span>Stock </span>
          <span class="font-weight-bold">{{ Purchase.length }}</span>
          <span class="mx-2">|</span>
          <span>Scan</span>
          <span class="font-weight-bold ml-1">{{ itemCount.length }}</span>
          <span class="mx-2">|</span>
          <span>Not Found</span>
          <span class="font-weight-bold ml-1">{{ foundCount }}</span>
        </div>
        <v-spacer></v-spacer>

        <v-btn
          color="info"
          :disabled="!selectedProduct || countStarted"
          @click="start()"
          >Start</v-btn
        >
        <v-btn color="teal" dark v-if="countStarted" @click.once="finshCount()"
          >Finsh</v-btn
        >
      </v-card-actions>
    </v-card>
    <v-card outlined class="mt-2" v-if="finsh">
      <v-card-text>
        <v-row justify="center">
          <v-col class="shrink" align="center">
            <h1 class="display-3 font-weight-light mb-1">{{ restockCount }}</h1>
            <v-btn color="success" :disabled="!checkRightStatus(40)"
              >Restock</v-btn
            >
          </v-col>
          <v-col class="shrink" align="center">
            <h1 class="display-3 font-weight-light mb-1">{{ foundCount }}</h1>
            <v-btn color="success" @click="createPurchase">Purchase</v-btn>
          </v-col>
          <v-col class="shrink" align="center">
            <h1 class="display-3 font-weight-light mb-1">
              {{ markItSoldCount }}
            </h1>
            <v-btn color="error" :disabled="!checkRightStatus(40)"
              >Mark It Sold</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card outlined class="mt-2">
      <v-card-text v-if="!finsh">
        <v-text-field
          @focus="$event.target.select()"
          label="Scan IMEI Number"
          v-model="imeiNumber"
          outlined
          dense
          hide-details
          @keyup.enter="searchImeiNumber"
          autocomplete
          ref="imeiNumber"
        ></v-text-field>
      </v-card-text>
      <v-divider></v-divider>
      <v-row>
        <v-col v-if="finsh">
          <!-- <div class="text-center subtitle-1"><kbd>In Your Stock</kbd></div> -->
          <v-data-table
            :headers="stockHeaders"
            hide-default-footer
            :itemsPerPage="500"
            no-data-text="No item on this invoice yet"
            :items="Purchase"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td class="text-left">
                  <span>{{ item.imeiNumber }}</span>
                  <v-chip
                    small
                    class="ma-2"
                    v-if="item.foundIt"
                    color="green"
                    text-color="white"
                  >
                    <span class="font-weight-bold">Found It</span>
                  </v-chip>
                  <v-chip
                    small
                    class="ma-2"
                    v-if="item.foundIt === false"
                    color="error"
                    text-color="white"
                  >
                    <span class="font-weight-bold">Not Scan</span>
                  </v-chip>
                  <v-chip
                    small
                    class="ma-2"
                    v-if="item.notFound"
                    color="yellow"
                    text-color="black"
                  >
                    <span class="font-weight-bold">Need To Purchase</span>
                  </v-chip>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
        <v-col v-else>
          <v-data-table
            :headers="headers"
            hide-default-footer
            :itemsPerPage="500"
            no-data-text="No item on this invoice yet"
            :items="itemCount"
          >
            <template v-slot:item="{ item, index }">
              <tr>
                <td>
                  <v-layout align-center justify-start row fill-height wrap>
                    <v-btn icon @click="deleteItem(index)">
                      <v-icon color="red">delete</v-icon>
                    </v-btn>
                    <span>{{ item.name }}</span>
                  </v-layout>
                </td>
                <td class="text-left">
                  <span>{{ item.imeiNumber }}</span>
                </td>
                <td class="text-left" v-if="!item.notFound">
                  <v-chip
                    v-if="!item.status"
                    class="ma-2"
                    color="green"
                    small
                    text-color="white"
                  >
                    <span class="font-weight-bold">In Stock</span>
                  </v-chip>
                  <v-chip
                    v-else
                    small
                    class="ma-2"
                    color="red"
                    text-color="white"
                  >
                    <span class="font-weight-bold">Out of Stock</span>
                  </v-chip>
                </td>
                <td v-else>
                  <v-chip small class="ma-2" color="yellow" text-color="black">
                    <span class="font-weight-bold">Not Found</span>
                  </v-chip>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
import productService from "@/modules/Product/service.js";
import purchaseService from "@/modules/Inventory/Purchase/service.js";
import { mapGetters } from "vuex";

export default {
  name: "setting",
  data() {
    return {
      selectedProduct: null,
      imeiNumber: null,
      productSearchLoading: false,
      Products: [],
      loading: false,
      countStarted: false,
      Purchase: [],
      itemCount: [],
      finsh: false,
      stockHeaders: [
        {
          text: "Serial Number",
          align: "left",
          value: "imeiNumber",
          sortable: false,
        },
      ],
      headers: [
        {
          text: "Name",
          align: "left",
          value: "name",
          sortable: false,
        },
        {
          text: "Serial Number",
          align: "left",
          value: "imeiNumber",
          sortable: false,
        },
        {
          text: "Status",
          align: "center",
          value: "state",
          width: "100",
          sortable: false,
        },
      ],
    };
  },
  created() {
    // this.registerStoreModule("setting", userStore);
    this.getProduct();
    document.title = "smplsale - Inventory Count";
  },
  watch: {
    phoneSearch(val) {
      if (val && val.length === 1) {
        this.getProduct();
      }
      if (val && val.length > 1) this.error = [];
    },
  },
  computed: {
    ...mapGetters("global", ["currentBusiness", "checkRightStatus"]),
    restockCount() {
      let value = 0;
      this.itemCount.map((row) => {
        if (row.status) value++;
      });
      return value;
    },
    markItSoldCount() {
      let value = 0;
      this.Purchase.map((row) => {
        if (row.foundIt === false) value++;
      });
      return value;
    },
    filterItems(item, queryText) {
      if (item && queryText) {
        return item.name.toLowerCase().includes(queryText.toLowerCase());
      }
      return null;
    },
    foundCount() {
      let value = 0;
      this.itemCount.map((row) => {
        if (row.notFound) value++;
      });
      return value;
    },
  },
  methods: {
    createPurchase() {
      this.$swal(
        "Create Purchase",
        "Create Purchase is not working Yet it will be ready soon"
      );
    },
    restock() {
      this.$swal({
        title: "Are you sure?",
        text: `You won't be able to revert this!`,
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.value) {
          this.itemCount.map(async (row) => {
            if (row.status) {
              await purchaseService.restock({ imeiNumber: row.imeiNumber });
            }
          });
        }
      });
    },
    markItSold() {
      this.$swal({
        title: "Are you sure?",
        text: `You won't be able to revert this!`,
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.value) {
          this.Purchase.map(async (row) => {
            if (row.foundIt === false) {
              await purchaseService.restock({ imeiNumber: row.imeiNumber });
            }
          });
        }
      });
    },
    deleteItem(i) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.value) {
          this.itemCount.splice(i, 1);
          // this.DeleteInvoiceDetailRow(i);
          // this.Invoice.InvoiceDetails.splice(i, 1);
        }
      });
    },
    searchImeiNumber() {
      const searchValue = {
        imeiNumber: this.imeiNumber,
      };

      this.Purchase.map((row) => {
        if (row.imeiNumber === this.imeiNumber) row.foundIt = true;
      });

      const imeiResult = this.itemCount.find(
        ({ imeiNumber }) => imeiNumber === searchValue.imeiNumber
      );

      if (imeiResult) {
        this.$swal({
          title: "Already Scan",
          text: `${searchValue.imeiNumber} is already counted!`,
          icon: "warning",
        });
        return false;
      }

      return purchaseService.getLastPurchase(searchValue).then((response) => {
        // console.log("response", response);
        if (response.data) {
          if (response.data.ProductId === this.selectedProduct.id) {
            this.itemCount.push(response.data);
          } else {
            this.$swal({
              title: "Not an same item",
              html: `<div>
                <span><strong>${this.imeiNumber}</strong> <span>is ${response.data.name}</span></span>
              </div>
              <div>and product you have selected is <strong>${this.selectedProduct.name}</strong></div>
              <div>Please scan same item</div>
              `,
              icon: "warning",
            });
          }
        } else {
          const product = {
            name: this.selectedProduct.name,
            cost: 0,
            imeiNumber: this.imeiNumber,
            notFound: true,
          };
          this.itemCount.push(product);
        }

        // console.log('"355770072473399"', this.Purchase);
        this.imeiNumber = null;
      });
      // const imeiResult = this.Purchase.find(
      //   ({ imeiNumber }) => imeiNumber === searchValue.imeiNumber
      // );
    },
    start() {
      // console.log("start count");
      this.countStarted = true;
      this.getAvailableIMEINumber(this.selectedProduct.id);
      this.$refs.imeiNumber.focus();
    },
    finshCount() {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.value) {
          this.itemCount.map((row) => {
            if (row.notFound) {
              this.Purchase.push(row);
            }
          });
          this.finsh = true;
        }
      });
    },
    getAvailableIMEINumber(id) {
      this.$Progress.start();
      const where = {
        status: false,
        ProductId: id,
      };
      return purchaseService.getPurchaseDetail(where).then((response) => {
        this.isLoading = false;
        response.data.map((row) => {
          Object.assign(row, { foundIt: false });
        });
        this.Purchase = response.data;
        // console.log("getAvailableIMEINumber", this.Purchase);
        return response;
      });
    },
    getProduct() {
      return productService
        .getAll({ trackSerialNumber: true })
        .then((result) => {
          // console.log("productService", result);
          this.Products = result.data;
        });
    },
  },
};
</script>

<template>
  <v-container grid-list-lg fluid>
    <v-card outlined>
      <v-toolbar dense flat color="grey lighten-3">
        <v-app-bar-nav-icon @click="$router.go(-1)">
          <v-icon>mdi-chevron-left</v-icon>
        </v-app-bar-nav-icon>

        <v-toolbar-title>Setting</v-toolbar-title>
        <v-spacer></v-spacer>
        <span v-if="Setting">Code:
          <span class="font-weight-medium">{{ Setting.code }}</span></span>
      </v-toolbar>

      <v-card-title primary-title> Business Info </v-card-title>
      <div v-if="Setting">
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12">
              <v-text-field outlined dense :error-messages="nameErrors" :hide-details="nameErrors.length === 0"
                v-model="Setting.name" label="Business Name"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-text-field outlined dense :error-messages="emailErrors" :hide-details="emailErrors.length === 0"
                v-model="Setting.email" label="Email Address"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-text-field outlined dense :error-messages="phoneNumberErrors"
                :hide-details="phoneNumberErrors.length === 0" v-model="Setting.phoneNumber"
                label="Phone Number"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field outlined hide-details dense v-model="Setting.website" label="Web Site"></v-text-field>
            </v-col>
            <v-card-title primary-title> Address </v-card-title>
            <v-col cols="12" sm="12">
              <v-text-field outlined hide-details dense v-model="Setting.address" label="Address"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-text-field outlined dense hide-details v-model="Setting.city" label="City"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="3">
              <v-text-field outlined dense hide-details v-model="Setting.state" label="State"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="3">
              <v-text-field outlined hide-details dense v-model="Setting.zip" label="Zip Code"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-textarea auto-grow hide-details outlined dense v-model="Setting.policy" label="Policy"></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-title primary-title> Options </v-card-title>
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Product
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row align="center">
                <v-col class="py-0">
                  <div class="subtitle-2">Product Cost</div>
                  <div>Choose which cost display in Product list average cost or real cost</div>
                </v-col>
                <v-col class="text-right shrink py-0">
                  <v-switch v-model="Setting.metadata.showAvgCostOnProduct" inset
                    :label="Setting.metadata.showAvgCostOnProduct ? 'Avg Cost' : 'Cost'"></v-switch>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col class="py-0">
                  <div class="subtitle-2">Dynamic Price</div>
                  <div>Price will change base on Dynamic Price List</div>
                </v-col>
                <v-col class="text-right shrink py-0">
                  <v-switch v-model="Setting.metadata.dynamicPrice" inset @change="changeDynamicPrice"
                    :label="Setting.metadata.dynamicPrice ? 'On' : 'Off'"></v-switch>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col class="py-0">
                  <div class="subtitle-2">Barcode</div>
                  <div>Show invoice number as an barcode on Invoice.</div>
                </v-col>
                <v-col class="text-right shrink py-0">
                  <v-switch v-model="Setting.metadata.barcodeOnInvoice" inset
                    :label="Setting.metadata.barcodeOnInvoice ? 'Show' : 'Hide'"></v-switch>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col class="py-0">
                  <div class="subtitle-2">Delete</div>
                  <div>
                    Extra precaution ask to type <span>delete</span> before deleting any item or product
                  </div>
                </v-col>
                <v-col class="text-right shrink py-0">
                  <v-switch v-model="Setting.metadata.deletePrecaution" inset :label="Setting.metadata.deletePrecaution ? 'Enable' : 'Disable'
                    "></v-switch>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col class="py-0">
                  <div class="subtitle-2">Out of Stock</div>
                  <div>Show out of stock label in product list.</div>
                </v-col>
                <v-col class="text-right shrink py-0">
                  <v-switch v-model="Setting.metadata.outOfStock" inset
                    :label="Setting.metadata.outOfStock ? 'Show' : 'Hide'"></v-switch>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col class="py-0">
                  <!-- <div class="subtitle-2">Out of Stock</div> -->
                  <div>Don't allow out of stock items to be sale.</div>
                </v-col>
                <v-col class="text-right shrink py-0">
                  <v-switch v-model="Setting.metadata.lockOutOfStock" inset
                    :label="Setting.metadata.lockOutOfStock ? 'Enable' : 'Disable'"></v-switch>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col class="py-0">
                  <div class="subtitle-2">Product description</div>
                  <div>Show or hide description on product list.</div>
                </v-col>
                <v-col class="text-right shrink py-0">
                  <v-switch v-model="Setting.metadata.productDescription" inset
                    :label="Setting.metadata.productDescription ? 'Show' : 'Hide'"></v-switch>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col class="py-0">
                  <div class="subtitle-2">Box Count</div>
                  <div>This feature allows you to enable box count on invoices and purchases.</div>
                </v-col>
                <v-col class="text-right shrink py-0">
                  <v-switch v-model="Setting.metadata.boxCountOnInventory" inset
                    :label="Setting.metadata.boxCountOnInventory ? 'Enable' : 'Disable'"></v-switch>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col class="py-0">
                  <div class="subtitle-2">Parent Chile Product</div>
                  <div>This feature allows you to create parent and child products under a parent product for reference.​</div>
                </v-col>
                <v-col class="text-right shrink py-0">
                  <v-switch v-model="Setting.metadata.parentChile" inset
                    :label="Setting.metadata.parentChile ? 'Enable' : 'Disable'"></v-switch>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Invoice
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row align="center">
                <v-col class="py-0">
                  <div class="subtitle-2">Serial Number</div>
                  <div>Print serial number on invoice.</div>
                </v-col>
                <v-col class="text-right shrink py-0">
                  <v-switch v-model="Setting.metadata.printSerialNumberOnInvoice" inset
                    :label="Setting.metadata.printSerialNumberOnInvoice ? 'Show' : 'Hide'"></v-switch>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col class="py-0">
                  <div class="subtitle-2">Open Invoice</div>
                  <div>Show open invoice list on every invoice.</div>
                </v-col>
                <v-col class="text-right shrink py-0">
                  <v-switch v-model="Setting.metadata.openInvoiceListOnInvoice" inset :label="Setting.metadata.openInvoiceListOnInvoice ? 'Show' : 'Hide'
                    "></v-switch>
                </v-col>
              </v-row>

              <v-row align="center">
                <v-col class="py-0" cols="8">
                  <div class="subtitle-2">Public Viewing Invoice</div>
                  <div>Limit on public viewing invoice.</div>
                </v-col>
                <v-col class="text-right py-0" cols="4">
                  <DateSelector v-model="Setting.metadata.invoiceLimit" :initValue="Setting.metadata.invoiceLimit"
                    :emptyDate="Setting.metadata.invoiceLimit ? false : true" />
                </v-col>
              </v-row>
              
              <v-row align="center">
                <v-col class="py-0" cols="8">
                  <div class="subtitle-2">Terms</div>
                  <div>Limit invoice due date days.</div>
                </v-col>
                <v-col class="text-right py-0" cols="4">
                  <DayPicker v-model="Setting.metadata.invoiceDue" :initValue="Setting.metadata.invoiceDue" @input="setDue" />
                </v-col>
              </v-row>

              <v-row align="center">
                <v-col class="py-0" cols="8">
                  <div class="subtitle-2">Invoice Viewing Limit</div>
                  <div>Set invoice default viewing limit.</div>
                </v-col>
                <v-col class="text-right py-0" cols="4">
                  <DateTimePicker :defaultRange="Setting.metadata.invoiceViewLimit ? Setting.metadata.invoiceViewLimit.range : null" :range="true"
                  :hideTime="true" v-model="Setting.metadata.invoiceViewLimit" :hideCustomOption="true" />
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col class="py-0">
                  <div class="subtitle-2">Invoice Due Date</div>
                  <div>Show due date on invoice list</div>
                </v-col>
                <v-col class="text-right shrink py-0">
                  <v-switch v-model="Setting.metadata.showDueDateOnInvoice" inset
                    :label="Setting.metadata.showDueDateOnInvoice ? 'Show' : 'Hide'"></v-switch>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col class="py-0">
                  <div class="subtitle-2">Lock Sales Rep</div>
                  <div> If this feature is enabled, a Sales Rep must be selected when creating or updating a invoice</div>
                </v-col>
                <v-col class="text-right shrink py-0">
                  <v-switch v-model="Setting.metadata.lockInvoiceSalesRep" inset
                    :label="Setting.metadata.lockInvoiceSalesRep ? 'Enable' : 'Disable'"></v-switch>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Payment
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row align="center">
                <v-col class="py-0">
                  <div class="subtitle-2">Payment Date</div>
                  <div>Option to show or hide payment date on Invoice.</div>
                </v-col>
                <v-col class="text-right shrink py-0">
                  <v-switch v-model="Setting.metadata.hidePaymentDate" inset
                    :label="Setting.metadata.hidePaymentDate ? 'Show' : 'Hide'"></v-switch>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col class="py-0">
                  <div class="subtitle-2">Payment Note</div>
                  <div>Show payment note on the invoice.</div>
                </v-col>
                <v-col class="text-right shrink py-0">
                  <v-switch v-model="Setting.metadata.showPaymentNoteOnInvoice" inset
                    :label="Setting.metadata.showPaymentNoteOnInvoice ? 'Show' : 'Hide'"></v-switch>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Inventory
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row align="center">
                <v-col class="py-0">
                  <div class="subtitle-2">Forced inventory tracking</div>
                  <div>Inventory tracking will automatically be turned on by default whenever you create a new product.</div>
                </v-col>
                <v-col class="text-right shrink py-0">
                  <v-switch v-model="Setting.metadata.ForcedInventoryTracking" inset
                    :label="Setting.metadata.ForcedInventoryTracking ? 'Enable' : 'Disable'"></v-switch>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col class="py-0">
                  <div class="subtitle-2">Purchase</div>
                  <div>This feature lets you update the selling price at the time of purchase. 
                    Once you add an item to the basket, 
                    it will update the selling price of the selected product.​</div>
                </v-col>
                <v-col class="text-right shrink py-0">
                  <v-switch v-model="Setting.metadata.updateProductPriceOnPurchase" inset
                    :label="Setting.metadata.updateProductPriceOnPurchase ? 'Enable' : 'Disable'"></v-switch>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col class="py-0">
                  <div class="subtitle-2">Lock Product Name</div>
                  <div>
                    Don't allow to change product name on invoices and purchases
                  </div>
                </v-col>
                <v-col class="text-right shrink py-0">
                  <v-switch v-model="Setting.metadata.lockProductName" inset :label="Setting.metadata.lockProductName ? 'Enable' : 'Disable'
                    "></v-switch>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col class="py-0">
                  <div class="subtitle-2">UPC Code</div>
                  <div>You can choose how you want the UPC to behave in your system.
                     With Direct Insert, the product will automatically drop into the
                      shopping cart after scanning or entering the UPC, making it ideal
                       for quick, single-item transactions. Alternatively, with Stay on Search Bar, the product will remain in the search bar after scanning, allowing you to manually enter the quantity before adding it to the cart. This option is helpful when you need to add multiple quantities of the same item.</div>
                </v-col>
                <v-col class="text-right shrink py-0">
                  <v-switch v-model="Setting.metadata.UPCbehave" inset
                    :label="Setting.metadata.UPCbehave ? 'Stay' : 'Insert'"></v-switch>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col class="py-0">
                  <div class="subtitle-2">Lock Reference Number</div>
                  <div> If this feature is enabled, a reference number must be entered when creating or updating a purchase</div>
                </v-col>
                <v-col class="text-right shrink py-0">
                  <v-switch v-model="Setting.metadata.lockPurchaseReferenceNumber" inset
                    :label="Setting.metadata.lockPurchaseReferenceNumber ? 'Enable' : 'Disable'"></v-switch>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col class="py-0">
                  <div class="subtitle-2">Status Update for Serialized Products</div>
                  <div> If this feature is enabled, users can change the status of serialized products while creating or updating a purchase.</div>
                </v-col>
                <v-col class="text-right shrink py-0">
                  <v-switch v-model="Setting.metadata.updateSerializeProductStatus" inset
                    :label="Setting.metadata.updateSerializeProductStatus ? 'Enable' : 'Disable'"></v-switch>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              CRM
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row align="center">
                <v-col class="py-0">
                  <div class="subtitle-2">Duplicate Customer Name</div>
                  <div>
                    Don't allow to create customer with same name
                  </div>
                </v-col>
                <v-col class="text-right shrink py-0">
                  <v-switch v-model="Setting.metadata.restrictDuplicateCustomerName" inset :label="Setting.metadata.restrictDuplicateCustomerName ? 'Enable' : 'Disable'
                    "></v-switch>
                </v-col>
              </v-row>

              

            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="Setting.metadata && Setting.metadata.dynamicPrice">
            <v-expansion-panel-header>
              Dynamic Price
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-data-table :headers="headers" :items="Setting.metadata.dynamicPriceList" hide-default-footer
                :items-per-page="Setting.metadata.dynamicPriceList.length" sort-by="from" class="elevation-0">
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title>Price List</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialog" max-width="500px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                          New Item
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title>
                          <span class="text-h5">{{ formTitle }}</span>
                        </v-card-title>

                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col cols="12" sm="6" md="4">
                                <v-text-field autofocus v-model="editedItem.from" label="From"></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="6" md="4">
                                <v-text-field v-model="editedItem.to" label="To"></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="6" md="4">
                                <v-text-field v-model="editedItem.markup" label="Mark Up"></v-text-field>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="blue darken-1" text @click="close">
                            Cancel
                          </v-btn>
                          <v-btn color="blue darken-1" text @click="save">
                            Save
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogDelete" max-width="500px">
                      <v-card>
                        <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                          <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-icon small class="mr-2" @click="editItem(item)">
                    mdi-pencil
                  </v-icon>
                  <v-icon small @click="deleteItem(item)">
                    mdi-delete
                  </v-icon>
                </template>
                <template v-slot:no-data>
                  <v-btn color="primary" @click="initialize">
                    Reset
                  </v-btn>
                </template>
              </v-data-table>

            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Remote Ordering
            </v-expansion-panel-header>
            <v-expansion-panel-content>

              <!-- <v-row>
                <v-col cols="12">
                  <v-text-field outlined dense hide-details v-model="Setting.metadata.dynamicSerializeLabel" label="Serialize"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field outlined dense hide-details v-model="Setting.metadata.dynamicNonSerializeLabel" label="Non Serialize"></v-text-field>
                </v-col>
              </v-row> -->

              <v-row align="center">
                <v-col class="py-0">
                  <div class="subtitle-2">Business</div>
                  <div>
                    View or Hide Name of your business
                  </div>
                </v-col>
                <v-col class="text-right shrink py-0">
                  <v-switch v-model="Setting.metadata.remoteOrderBusinessName" inset :label="Setting.metadata.remoteOrderBusinessName ? 'View' : 'Hide'
                    "></v-switch>
                </v-col>
              </v-row>

              <v-row align="center">
                <v-col class="py-0">
                  <div class="subtitle-2">Category</div>
                  <div>
                    View or Hide Categories While Ordering on top of the list
                  </div>
                </v-col>
                <v-col class="text-right shrink py-0">
                  <v-switch v-model="Setting.metadata.remoteOrderCategory" inset :label="Setting.metadata.remoteOrderCategory ? 'View' : 'Hide'
                    "></v-switch>
                </v-col>
              </v-row>


              <v-row align="center">
                <v-col class="py-0">
                  <div class="subtitle-2">Stock</div>
                  <div>
                    Show or hide stock count
                  </div>
                </v-col>
                <v-col class="text-right shrink py-0">
                  <v-switch v-model="Setting.metadata.remoteOrderStockCount" inset :label="Setting.metadata.remoteOrderStockCount ? 'Show' : 'Hide'
                    "></v-switch>
                </v-col>
              </v-row>

              <v-row align="center">
                <v-col class="py-0">
                  <div class="subtitle-2">Invoice</div>
                  <div>
                    Hide reorder QR Code on the invoice
                  </div>
                </v-col>
                <v-col class="text-right shrink py-0">
                  <v-switch v-model="Setting.metadata.QRCodeOnInvoice" inset :label="Setting.metadata.QRCodeOnInvoice ? 'Hide' : 'Show'
                    "></v-switch>
                </v-col>
              </v-row>

              <v-row align="center">
                <v-col class="py-0">
                  <div class="subtitle-2">Color</div>
                  <div>
                    Make it your own change background color of you order screen.
                  </div>
                </v-col>
                <v-col class="text-right shrink py-0">
                  <v-color-picker dot-size="30" mode="hexa" v-model="color"></v-color-picker>

                </v-col>
              </v-row>

              <v-row align="center">
                <v-col class="py-0">
                  <!-- <div class="subtitle-2">Color</div> -->
                  <div>
                    Dark Mode <v-btn :dark="Setting.metadata.remoteOrderDarkMode" class="ml-2" :color="showColor">Test
                      Button</v-btn>
                  </div>
                </v-col>
                <v-col class="text-right shrink py-0">
                  <v-switch v-model="Setting.metadata.remoteOrderDarkMode" inset :label="Setting.metadata.remoteOrderDarkMode ? 'Enable' : 'Disable'
                    "></v-switch>
                </v-col>
              </v-row>




            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Modules
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row align="center">
                <v-col class="py-0">
                  <div class="subtitle-2">Serialize</div>
                  <div>
                    Sale product with serial number, this feature allow you to keep
                    track items with serial numbers.
                  </div>
                </v-col>
                <v-col class="text-right shrink py-0">
                  <v-switch v-model="Setting.metadata.serializeProduct" inset :label="Setting.metadata.serializeProduct ? 'Enable' : 'Disable'
                    "></v-switch>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col class="py-0">
                  <div class="subtitle-2">Work Order</div>
                  <div>Enable work order feature.</div>
                </v-col>
                <v-col class="text-right shrink py-0">
                  <v-switch v-model="Setting.metadata.workOrder" inset :label="Setting.metadata.workOrder ? 'Show' : 'Hide'
                    "></v-switch>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>





        <v-divider></v-divider>
        <v-card-title primary-title>
          <v-row>
            <v-col cols="12"> Logo </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12">
              <v-btn icon color="red">
                <v-icon>delete</v-icon>
              </v-btn>
              <v-btn icon color="info" @click="pickFile">
                <v-icon>attach_file </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>



        <v-card-text>
          <v-layout align-center justify-center column fill-height>
            <v-layout class="ma-6" align-center justify-center column fill-height>
              <img height="120" v-if="imageUrl" :src="imageUrl" alt="Logo" />
              <img height="120" v-else src="../../assets/images/uploadphoto.png" alt="Logo" />
            </v-layout>
          </v-layout>

          <input type="file" ref="image" style="display: none" accept="image/*" @change="onFilePicked" />
        </v-card-text>

        <v-card-text>
          <v-row>
            <v-card-title primary-title> Email Setting </v-card-title>
            <v-col cols="12" sm="12">
              <v-text-field outlined hide-details dense v-model="Setting.smtpEmailAddress"
                label="Email Address"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-text-field outlined dense hide-details v-model="Setting.smtpHost" label="SMTP Setting"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="3">
              <v-text-field outlined dense hide-details v-model="Setting.smtpPort" label="SMTP Port"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="3">
              <v-text-field outlined hide-details dense v-model="Setting.smtpPassword" label="Password"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>


      </div>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="pink" dark :loading="loading" @click="submit()">update</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>
<script>
import RegisterStoreModule from "@/mixins/RegisterStoreModule";
import DateSelector from "@/components/DateSelector";
import DayPicker from "@/components/DayPicker";
import DateTimePicker from "@/components/DateTimePicker";
import userStore from "./store.js";
import settingService from "./service";
import { mapActions, mapGetters } from "vuex";

const { validationMixin } = require("vuelidate");
const { required, minLength, email } = require("vuelidate/lib/validators");

export default {
  name: "setting",
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: 'From ($)',
          value: 'from',
        },
        { text: 'To ($)', value: 'to' },
        { text: 'Mark Up (%)', value: 'markup' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      editedIndex: -1,
      editedItem: {
        from: 0,
        to: 0,
        markup: 0,
      },
      defaultItem: {
        from: 0,
        to: 0,
        markup: 0,
      },
      type: 'hex',
      hex: '#F0C316',
      loading: true,
      imageName: "",
      imageUrl: "",
      imageFile: "",
      Setting: null,
      metadata: {
        dynamicPrice: false,
        hidePaymentDate: false,
        openInvoiceListOnInvoice: false,
        workOrder: false,
        barcodeOnInvoice: true,
        outOfStock: false,
        lockOutOfStock: false,
        showAvgCostOnProduct: false,
        productDescription: false,
        serializeProduct: false,
        deletePrecaution: false,
        lockProductName: true,
        restrictDuplicateCustomerName: false,
        dynamicPriceList: [],
        dynamicSerializeLabel: 'Serialize',
        dynamicNonSerializeLabel: 'Non Serialize',
        invoiceLimit: null,
        invoiceDue: null,
        invoiceViewLimit: null
      },
    };
  },
  created() {
    this.registerStoreModule("setting", userStore);
    this.getSetting();
    document.title = "smplsale - Setting";
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },
  // watch: {
  //   imageUrl(val) {
  //     this.Setting.image = val;
  //   },
  // },
  components: {
    DateSelector,
    DayPicker,
    DateTimePicker
  },
  computed: {
    ...mapGetters("global", ["currentBusiness"]),
    color: {
      get() {
        return this[this.type]
      },
      set(v) {
        this[this.type] = v
      },
    },
    showColor() {
      if (typeof this.color === 'string') return this.color

      return JSON.stringify(Object.keys(this.color).reduce((color, key) => {
        color[key] = Number(this.color[key].toFixed(2))
        return color
      }, {}), null, 2)
    },
    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.Setting.email.$dirty) return errors;
      if (!this.$v.Setting.email) errors.push("Email is not valid");
      if (!this.$v.Setting.email.required) errors.push("Email is required.");
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.Setting.name.$dirty) return errors;
      if (!this.$v.Setting.name.minLength)
        errors.push("Field must be at least 5 characters long");
      if (!this.$v.Setting.name.required)
        errors.push("Business Name is required.");
      return errors;
    },
    phoneNumberErrors() {
      const errors = [];
      if (!this.$v.Setting.phoneNumber.$dirty) return errors;
      if (!this.$v.Setting.phoneNumber.required)
        errors.push("Phone Number is required.");
      return errors;
    },
  },
  mixins: [validationMixin, RegisterStoreModule],
  validations: {
    Setting: {
      name: {
        required,
        minLength: minLength(5),
      },
      email: {
        required,
        email,
      },
      phoneNumber: {
        required,
      },
    },
  },
  methods: {
    ...mapActions("global", ["initBusiness"]),
    changeDynamicPrice(val) {
      console.log('changeDynamicPrice', val)
      if (val) {
        if (this.Setting.metadata && this.Setting.metadata && !this.Setting.metadata.dynamicPriceList) {
          this.Setting.metadata.dynamicPriceList = []
          console.log('this.Setting.metadata.dynamicPriceList')
          this.initialize()
        }
      }
    },
    initialize() {
      this.metadata.dynamicPriceList = [
        { from: 1, to: 5, markup: 75 },
        { from: 6, to: 10, markup: 50 },
        { from: 11, to: 15, markup: 40 },
        { from: 16, to: 20, markup: 30 },
        { from: 21, to: 99, markup: 25 },
        { from: 100, to: 199, markup: 23 },
        { from: 200, to: 300, markup: 20 },
        { from: 301, to: 399, markup: 15 },
        { from: 400, to: 500, markup: 10 },
        { from: 501, to: 599, markup: 8 },
        { from: 600, to: 700, markup: 5 },
      ]
    },
    editItem(item) {
      this.editedIndex = this.Setting.metadata.dynamicPriceList.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    setDue(invoiceDue){
      this.Setting.metadata.invoiceDue = invoiceDue
    },

    deleteItem(item) {
      this.editedIndex = this.Setting.metadata.dynamicPriceList.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.Setting.metadata.dynamicPriceList.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.Setting.metadata.dynamicPriceList[this.editedIndex], this.editedItem)
      } else {
        this.Setting.metadata.dynamicPriceList.push(this.editedItem)
      }
      this.close()
    },
    pickFile() {
      this.$refs.image.click();
    },
    onFilePicked(e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.imageName = files[0].name;
        if (this.imageName.lastIndexOf(".") <= 0) {
          return;
        }
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener("load", () => {
          this.imageUrl = fr.result;
          this.Setting.image = this.imageUrl;
          this.imageFile = files[0]; // this is an image file that can be sent to server...
        });
      } else {
        this.imageName = "";
        this.imageFile = "";
        this.imageUrl = "";
      }
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          })
          .fire({
            icon: "error",
            title: this.$t("toastMessage.text.invalidFields"),
          });
      } else {
        this.loading = true;
        // this.Setting.metadata = this.metadata
        // console.log("setting", this.Setting);
        this.Setting.metadata.remoteOrderBackgroundColor = this.showColor
        return settingService
          .update(this.Setting.id, this.Setting)
          .then((result) => {
            if (result.status) {
              this.$swal
                .mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                })
                .fire({
                  icon: "success",
                  title: "Setting is updated!",
                });
              this.initBusiness(this.Setting);
              this.loading = false;
              this.$router.go(-1);
            }
          })
          .catch((err) => {
            this.loading = false;
            this.$swal
              .mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              })
              .fire({
                icon: "error",
                title: err.data.message,
              });
          });
      }
    },
    getSetting() {
      this.loading = true;
      return settingService.getById(this.currentBusiness.id).then((result) => {
        // // console.log("got setting", result, this.metadata);
        this.Setting = result.data;
        this.Setting.metadata = {
          ...this.metadata,
          ...this.Setting.metadata,
        };

        // if (!this.Setting.metadata) {
        //   // console.log("in here");
        //   this.Setting.metadata = {
        //     hidePaymentDate: false,
        //   };
        // }
        // if (!this.Setting.metadata.openInvoiceListOnInvoice) {
        //   this.Setting.metadata.openInvoiceListOnInvoice = false;
        // }

        if (this.Setting.imageUrl) this.imageUrl = this.Setting.imageUrl;

        // // console.log("got setting", this.Setting);
        this.loading = false;
      });
    },
  },
};
</script>
